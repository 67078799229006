import React from 'react'

import numberBetween from '@u/numberBetween'

import './index.css'

const ProgressBar = ({ percent }) => (
  <div className="progress-bar">
    <div className="progress-bar-value" style={{ width: `${percent}%` }} />
  </div>
)

ProgressBar.defaultValues = {
  percent: 0
}
ProgressBar.propTypes = {
  percent: numberBetween(1, 100)
}

export default ProgressBar
