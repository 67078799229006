import React from 'react'
import { graphql } from 'gatsby'
import DatoCMSModel from '../../models/DatoCMSModel'

import Layout from '@c/layout'
import ProgressBar from '@c/progress-bar'

import Cards from '@v/hello/cards'

import './index.css'
import ProjectImage from '@/components/project-image'

const Page = ({ data }) => {
  const { page } = data
  const title = `${page.titleSmall} ${page.titleBig}`
  const model = new DatoCMSModel(page)

  return (
    <Layout
      locale={page.locale}
      seo={{ ...model.getSeoComponentProps(null, { title }) }}
    >
      {page.imageLeft && (
        <ProjectImage
          className="hello-image-left d-none d-lg-block"
          image={page.imageLeft}
          width="342"
          height="522"
          style={{ width: 'auto' }}
        />
      )}
      {page.imageRight && (
        <ProjectImage
          className="hello-image-right d-none d-lg-block"
          image={page.imageRight}
          width="347"
          height="489"
          style={{ width: 'auto' }}
        />
      )}

      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-2">
            <div className="row">
              {/* Header */}
              <div className="col-12 mb-60">
                <h4 className="fw-head-normal">{page.titleSmall}</h4>
                <h1>{page.titleBig}</h1>
                <div className="mt-24">
                  <ProgressBar percent={25} />
                </div>
                <div
                  className="mt-24"
                  dangerouslySetInnerHTML={{ __html: page.description }}
                />
              </div>
            </div>

            <Cards
              locale={page.locale}
              slug={page.slug}
              cards={page.cards}
              pages={page.contactPages}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query HelloPageQuery($locale: String) {
    page: datoCmsHelloPage(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      slug

      titleSmall
      titleBig
      description
      imageLeft {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      imageRight {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }

      cards: contactCardsAndPages {
        ... on DatoCmsContactCard {
          title
          description
          linkText
          image {
            alt
            asset {
              localImage {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }

          isServiceCard
        }
      }

      contactPages: contactCardsAndPages {
        ... on DatoCmsHelloContactPage {
          slug
          isServicePage
        }
      }
    }
  }
`
