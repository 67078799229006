import React from 'react'
import { Link } from 'gatsby'

import Icon from '@c/icon'
import makeSlug from '@u/make-slug'
import ProjectImage from '@/components/project-image'

const Cards = ({ locale, slug, cards, pages }) => {
  const service = cards
    .filter(card => !!card.title)
    .find(card => card.isServiceCard)
  const join = cards
    .filter(card => !!card.title)
    .find(card => !card.isServiceCard)
  const servicePage = pages
    .filter(page => !!page.slug)
    .find(page => page.isServicePage)
  const joinPage = pages
    .filter(page => !!page.slug)
    .find(page => !page.isServicePage)

  return (
    <div className="row">
      {service && servicePage && (
        <div className="col-12 col-lg-6">
          <Link
            to={makeSlug.generic(locale, slug, servicePage.slug)}
            className="border-box border-box--hover"
          >
            {service.image && <ProjectImage image={service.image} />}

            <h5 className="mt-32">{service.title}</h5>
            <p className="mt-8">{service.description}</p>

            <p className="mt-24">
              {service.linkText}
              <Icon name="right" className="ml-8" />
            </p>
          </Link>
        </div>
      )}

      {/* - */}

      {join && joinPage && (
        <div className="col-12 col-lg-6">
          <Link
            to={makeSlug.generic(locale, slug, joinPage.slug)}
            className="border-box border-box--hover"
          >
            {join.image && <ProjectImage image={join.image} />}

            <h5 className="mt-32">{join.title}</h5>
            <p className="mt-8">{join.description}</p>

            <p className="mt-24">
              {join.linkText}
              <Icon name="right" className="ml-8" />
            </p>
          </Link>
        </div>
      )}
    </div>
  )
}

export default Cards
